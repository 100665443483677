import { useEffect, useState } from "react";
import { Button, Variant } from "../../../components/ui";
import { Gif, ReactIcons } from "../../../assets";
import ConnectWalletDialog from "../../../components/ConnectWalletDialog";
import { Tooltip } from "react-tooltip";
import { useRecoilState } from "recoil";
import ApproveRouterJson from "../../../utils/abi/ApproveRouterAbi.json";
import { AbiItem } from "web3-utils";
import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import { MintState, mintingState } from "../../../recoil/mint";
import MintDetailsSelectorCard from "./MintDetailsSelectorCard";
import {
  ARBITRUM_CFRM,
  ARBITRUM_FRM,
  BSC_CFRM,
  BSC_FRM,
} from "../../../utils/constants";
import { CrucibleClient } from "../../../utils/contractSync/CrucibleClient";
import { Web3Helper } from "../../../utils/Web3Helper";
import Web3 from "web3";
import Big from "big.js";
import { MessageState, messagingState } from "../../../recoil/app";

const MintCard = () => {
  const [mintState, setMintState] = useRecoilState<MintState>(mintingState);
  const [messageState,setMessageState] = useRecoilState<MessageState>(messagingState);

  const {
    isMinting,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    amount,
    isApproving,
    allowance,
  } = mintState;
  const [showConnectWalletDialog, setShowConnectWalletDialog] =
    useState<boolean>(false);
  const { isConnected, walletAddress, currentNetworkChainId, web3Sdk } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();

  const {
    hash,
    status,
    error,
    reset,
    sendWeb3Transaction,
  } = useFoundrySendTransaction();
  
  useEffect(()=>{
    setMessageState({
      errorMessage:"",
      successMessage:""
    })
  },[])

  useEffect(() => {
    if (destinationNetworkAndToken?.tokenAddress && walletAddress) {
      getCrucibleInfo();
      getUserCrucibleInfo();
    }
  }, [destinationNetworkAndToken?.tokenAddress, walletAddress]);

  useEffect(() => {
    if (isConnected) {
      setShowConnectWalletDialog(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && currentNetworkChainId && !sourceNetworkAndToken) {
      if (currentNetworkChainId === ARBITRUM_FRM.networkId) {
        setMintState({
          ...mintState,
          sourceNetworkAndToken: ARBITRUM_FRM,
          destinationNetworkAndToken: ARBITRUM_CFRM,
        });
      } else if (currentNetworkChainId === BSC_FRM.networkId) {
        setMintState({
          ...mintState,
          sourceNetworkAndToken: BSC_FRM,
          destinationNetworkAndToken: BSC_CFRM,
        });
      } else {
        setMintState({
          ...mintState,
          sourceNetworkAndToken: ARBITRUM_FRM,
          destinationNetworkAndToken: ARBITRUM_CFRM,
        });
      }
    }
  }, [isConnected, currentNetworkChainId, sourceNetworkAndToken]);

  useEffect(() => {
    if (
      sourceNetworkAndToken?.networkId &&
      sourceNetworkAndToken?.tokenAddress &&
      walletAddress
    ) {
      getCurrentApprovedAmount(walletAddress, false,false);
    }
  }, [sourceNetworkAndToken?.tokenAddress, sourceNetworkAndToken?.networkId]);

  useEffect(() => {
    if (isMinting && hash) {
      if(status === "success"){
        reset();
        getCurrentApprovedAmount(walletAddress, false, true);
      }
    }else if (isMinting && error) {
      setMintState({
        ...mintState,
        isMinting: false,
        isApproving: false,
        amount: "",
      });
      setMessageState({
        errorMessage: "An error occured while minting",
        successMessage: "",
      })
      reset();
    }
    
  }, [isMinting, hash, status,error]);


  useEffect(() => {
    if (isApproving && hash && status === "success") {
      reset();
      getCurrentApprovedAmount(walletAddress, true, false);
    } else if (isApproving && error) {
      setMintState({
        ...mintState,
        isMinting: false,
        isApproving: false,
        
        amount: "",
      });
      setMessageState({
        errorMessage: "An error occured while approving",
        successMessage: "",
      })
      reset();
    }
  }, [isApproving, hash, status,error]);

  const getCrucibleInfo = async () => {
    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const info = await client.getCrucible(
        `${destinationNetworkAndToken.crucibleName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        destinationNetworkAndToken.stakingAddress
      );
      console.log("Crucible info : ",info)
    }
  };

  const getUserCrucibleInfo = async () => {
    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const userInfo = await client.getUserCrucibleInfo(
        `${destinationNetworkAndToken.crucibleName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        destinationNetworkAndToken.stakingAddress,
        walletAddress
      );
      console.log("User info : ",userInfo)
    }
  };
  const getCurrentApprovedAmount = async (
    walletAddress: string,
    isAfterApproval: boolean,
    isAfterMint: boolean,
  ) => {
    const web3 = new Web3(sourceNetworkAndToken?.networkRpcUrl);
    const web3Helper = new Web3Helper(web3);
    const response = await web3Helper.getStandardContractAllocation(
      String(sourceNetworkAndToken?.tokenAddress),
      walletAddress,
      sourceNetworkAndToken?.router!
    );
    if (isAfterApproval) {
      setMintState({
        ...mintState,
        allowance: response.allowance,
        isApproving: false,
        isMinting: true,
      });
      setMessageState({
        successMessage:"",
        errorMessage:""
      })
      handleMintAction(isAfterApproval);
    }else if (isAfterMint){
      setMintState({
        ...mintState,
        amount:"",
        allowance: response.allowance,
        isApproving: false,
        isMinting: false,
      });
      setMessageState({
        errorMessage:"",
        successMessage:"Mint successfull",
      })
    }else{
      setMintState({
        ...mintState,
        
        allowance: response.allowance,
        isApproving: false,
        isMinting: false,
      });
      setMessageState({
        errorMessage:"",
        successMessage:"",
      })
    }
  };

  const approveContractAllocation = async (
    walletAddress: string,
    web3Sdk: any,
    amount: string
  ) => {
    try {
      setMintState({
        ...mintState,
        isApproving: true,
        isMinting: false,
      });
      setMessageState({
        errorMessage:"",
        successMessage:"",
      })
      const contractInstance = new web3Sdk.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        sourceNetworkAndToken?.tokenAddress
      );

      const decimals = await contractInstance.methods.decimals().call();
      const payload = {
        from: walletAddress,
        to: sourceNetworkAndToken?.tokenAddress,
        data: contractInstance.methods
          .approve(
            sourceNetworkAndToken?.router,
            new Big(amount)
              .times(10 ** Number(decimals))
              .toFixed()
              .toString()
          )
          .encodeABI(),
      };
      sendWeb3Transaction(payload);
    } catch (error) {
      setMintState({
        ...mintState,
        isApproving: false,
        isMinting: false,
        
      });
      setMessageState({
        successMessage: "",
        errorMessage: "An error occured"
      })
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  };

  const handleApproveAction = () => {
    if (
      sourceNetworkAndToken?.networkId &&
      sourceNetworkAndToken?.tokenAddress &&
      destinationNetworkAndToken?.networkId &&
      destinationNetworkAndToken?.tokenAddress &&
      amount
    ) {
      approveContractAllocation(
        walletAddress,
        new Web3(sourceNetworkAndToken?.networkRpcUrl),
        amount
      );
    }
  };

  const handleMintAction = async (isAfterApproval:boolean) => {
    if(!isAfterApproval){
      setMintState({ ...mintState,isMinting: true });
      setMessageState({
        errorMessage: "",successMessage:""
      })
    }
    
    // minting logic using sourceNetworkAndToken

    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const tx = await client.deposit(
        `${destinationNetworkAndToken.networkName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        `${sourceNetworkAndToken.networkName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        amount,
        walletAddress
      );
      if (tx) {
        const payload = {
          from: tx.from,
          to: tx.contract,
          data: tx.data,
          value: tx.value,
        };
        sendWeb3Transaction(payload);
      }
    }
  };

  const getButtonVariant = (): Variant => {
    return isMinting || isApproving ? "tertiary" : "primary";
  };

  const isButtonDisabled = () => {
    return isMinting || isApproving;
  };

  const getButtonPostfix = () => {
    if (false) {
      return <ReactIcons.GoLinkExternal />;
    }
  };

  const getButtonPrefix = () => {
    if (isMinting || isApproving) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };

  const getButtonTitle = () => {
    if (!isConnected) {
      return "Connect Wallet";
    }

    if (isConnected && !sourceNetworkAndToken) {
      return "Select Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      return "Switch Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(amount) || Number(amount) <= 0)
    ) {
      return "Enter Amount";
    }

    if (isMinting) {
      return "Minting";
    }

    if (isApproving) {
      return "Approving";
    }

    if (
      Number.isNaN(allowance) ||
      Number(allowance) <= 0 ||
      Number(allowance) < Number(amount)
    ) {
      return "Mint";
    }

    return "Mint";
  };

  const handleButtonClick = async () => {
    if (!isConnected) {
      setShowConnectWalletDialog(true);
      return;
    }

    if (isConnected && !sourceNetworkAndToken) {
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      switchWeb3Network(sourceNetworkAndToken.networkId.toString());
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(amount) || Number(amount) <= 0)
    ) {
      return;
    }

    if (
      Number.isNaN(allowance) ||
      Number(allowance) <= 0 ||
      Number(allowance) < Number(amount)
    ) {
      handleApproveAction();
      return;
    }
    handleMintAction(false);
  };

  return (
    <>
      <MintDetailsSelectorCard title="You’ll Deposit" side="source" balanceUpdated={status === "success"} />

      <MintDetailsSelectorCard title="You’ll Receive" side="destination" balanceUpdated={status === "success"} />

      <Button
        variant={`${getButtonVariant()}`}
        title={`${getButtonTitle()}`}
        className="mt-4 w-full text-xl"
        prefix={getButtonPrefix()}
        postfix={getButtonPostfix()}
        disabled={isButtonDisabled()}
        onClick={handleButtonClick}
      />

      <ConnectWalletDialog
        show={showConnectWalletDialog}
        onHide={() => setShowConnectWalletDialog(false)}
      />
    </>
  );
};

export default MintCard;
