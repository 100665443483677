import clsx from "clsx";
import React, { JSX } from "react";

export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "quinary"
  | "sixth";

export const Button = ({
  variant = "primary",
  title,
  prefix,
  postfix,
  disabled = false,
  rounded = "md",
  className,
  onClick,
  style,
}: {
  variant: Variant;
  title?: string;
  prefix?: JSX.Element;
  postfix?: JSX.Element;
  rounded?: "none" | "sm" | "md" | "lg" | "xl" | "full";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}) => {
  const classes = clsx(
    {
      "text-md bg-primary p-2 font-medium text-black": variant === "primary",
      "border border-primary p-2 font-medium text-white":
        variant === "secondary",
      "bg-tertiary p-2 font-medium text-primary": variant === "tertiary",
      "border border-backgroundLight bg-backgroundDark p-2 font-medium text-white":
        variant === "quaternary",
      "border border-backgroundLight p-2 font-medium text-white":
        variant === "quinary",
      "bg-black p-2 font-medium text-backgroundLight": variant === "sixth",
      "rounded-none": rounded === "none",
      "rounded-sm": rounded === "sm",
      "rounded-md": rounded === "md",
      "rounded-lg": rounded === "lg",
      "rounded-xl": rounded === "xl",
      "rounded-full": rounded === "full",
      "flex items-center justify-center": prefix || postfix,
    },
    className,
  );

  return (
    <button
      type="button"
      className={classes}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {prefix && <span className="mr-2">{prefix}</span>}
      {title}
      {postfix && <span className="ml-2">{postfix}</span>}
    </button>
  );
};
