import { useEffect, useState } from "react";
import { Button, Variant } from "../../../components/ui";
import { Gif, ReactIcons } from "../../../assets";

import ConnectWalletDialog from "../../../components/ConnectWalletDialog";
import { Tooltip } from "react-tooltip";
import { useRecoilState } from "recoil";

import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import { useTokenBalance } from "../../../hooks";
import { UnWrapState, unWrapingState } from "../../../recoil/unWrap";
import UnWrapDetailsSelectorCard from "./UnWrapDetailsSelectorCard";
import { CrucibleClient } from "../../../utils/contractSync/CrucibleClient";
import {
  ARBITRUM_CFRM,
  ARBITRUM_FRM,
  BSC_CFRM,
  BSC_FRM,
} from "../../../utils/constants";
import { MessageState, messagingState } from "../../../recoil/app";

const UnWrapCard = () => {
  const [unWrapState, setUnWrapState] =
    useRecoilState<UnWrapState>(unWrapingState);
  const [messageState, setMessageState] =
    useRecoilState<MessageState>(messagingState);
  const [isFeeLoading, setIsFeeLoading] = useState<boolean>(false);

  const {
    isUnWraping,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    amount,
    finalUnWrapAmount,
    feeOnUnWrap,
  } = unWrapState;

  const [showConnectWalletDialog, setShowConnectWalletDialog] =
    useState<boolean>(false);
  const { isConnected, walletAddress, currentNetworkChainId } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();

  const { hash, status, error, reset, sendWeb3Transaction } =
    useFoundrySendTransaction();

  const getCrucibleInfoAndUserInfo = async () => {
    if (destinationNetworkAndToken && sourceNetworkAndToken && walletAddress) {
      const client = new CrucibleClient();
      const info = await client.getCrucible(
        `${sourceNetworkAndToken.crucibleName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        sourceNetworkAndToken.stakingAddress
      );
      const userInfo = await client.getUserCrucibleInfo(
        `${sourceNetworkAndToken.crucibleName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        sourceNetworkAndToken.stakingAddress,
        walletAddress
      );
      console.log("userInfo", userInfo);
      console.log("crucibleInfo", info);
      console.log("feed on transfer", info?.data.feeOnWithdrawRate);
      setUnWrapState({
        ...unWrapState,
        finalUnWrapAmount: Number(
          Number(amount) -
            Number(amount) *
              (Number(
                info?.data.feeOnWithdrawRate
                  ? (Number(info?.data.feeOnWithdrawRate) * 100).toString()
                  : "0"
                    ? info?.data.feeOnWithdrawRate
                      ? (Number(info?.data.feeOnWithdrawRate) * 100).toString()
                      : "0.0"
                    : "0.0"
              ) /
                100)
        ).toString(),
        feeOnUnWrap: info?.data.feeOnWithdrawRate
          ? (Number(info?.data.feeOnWithdrawRate) * 100).toString()
          : "0",
      });
      setIsFeeLoading(false);
    }
  };

  useEffect(() => {
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
  }, []);

  useEffect(() => {
    if (
      destinationNetworkAndToken?.tokenAddress &&
      sourceNetworkAndToken?.tokenAddress &&
      walletAddress
    ) {
      setIsFeeLoading(true);
      getCrucibleInfoAndUserInfo();
    }
  }, [destinationNetworkAndToken, walletAddress, sourceNetworkAndToken]);

  useEffect(() => {
    if (isConnected) {
      setShowConnectWalletDialog(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && currentNetworkChainId && !sourceNetworkAndToken) {
      if (currentNetworkChainId === ARBITRUM_CFRM.networkId) {
        setUnWrapState({
          ...unWrapState,
          sourceNetworkAndToken: ARBITRUM_CFRM,
          destinationNetworkAndToken: ARBITRUM_FRM,
        });
      } else if (currentNetworkChainId === BSC_CFRM.networkId) {
        setUnWrapState({
          ...unWrapState,
          sourceNetworkAndToken: BSC_CFRM,
          destinationNetworkAndToken: BSC_FRM,
        });
      } else {
        setUnWrapState({
          ...unWrapState,
          sourceNetworkAndToken: ARBITRUM_CFRM,
          destinationNetworkAndToken: ARBITRUM_FRM,
        });
      }
    }
  }, [isConnected, currentNetworkChainId, sourceNetworkAndToken]);

  useEffect(() => {
    if (isUnWraping && hash && status === "success") {
      setUnWrapState({
        ...unWrapState,
        isUnWraping: false,
        amount: "",
        finalUnWrapAmount:""
      });
      setMessageState({
        errorMessage: "",
        successMessage: "Un wrapping successful",
      });
      reset();
    } else if (isUnWraping && error) {
      setUnWrapState({
        ...unWrapState,
        isUnWraping: false,
      });
      setMessageState({
        errorMessage: "An error occured while un wrapping",
        successMessage: "",
      });
      reset();
    }
  }, [isUnWraping, hash, status, error]);

  const handleUnWrapAction = async () => {
    setUnWrapState({
      ...unWrapState,
      isUnWraping: true,
    });
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
    // un wrap logic using sourceNetworkAndToken

    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const tx = await client.withdraw(
        `${sourceNetworkAndToken.networkName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        `${destinationNetworkAndToken.networkName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        amount,
        walletAddress
      );
      if (tx) {
        const payload = {
          from: tx.from,
          to: tx.contract,
          data: tx.data,
        };
        sendWeb3Transaction(payload);
      }
    }
  };

  const getButtonVariant = (): Variant => {
    return isUnWraping || isFeeLoading ? "tertiary" : "primary";
  };

  const isButtonDisabled = () => {
    return isUnWraping || isFeeLoading;
  };

  const getButtonPostfix = () => {
    if (false) {
      return <ReactIcons.GoLinkExternal />;
    }
  };

  const getButtonPrefix = () => {
    if (isUnWraping) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };

  const getButtonTitle = () => {
    if (isFeeLoading) {
      return "Loading Configuration";
    }

    if (!isConnected) {
      return "Connect Wallet";
    }

    if (isConnected && !sourceNetworkAndToken) {
      return "Select Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      return "Switch Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(finalUnWrapAmount) || Number(finalUnWrapAmount) <= 0)
    ) {
      return "Enter Amount";
    }
    if (isUnWraping) {
      return "UnWraping";
    }

    return "UnWrap";
  };

  const handleButtonClick = () => {
    if (isFeeLoading) {
      return;
    }

    if (!isConnected) {
      setShowConnectWalletDialog(true);
      return;
    }

    if (isConnected && !sourceNetworkAndToken) {
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      switchWeb3Network(sourceNetworkAndToken.networkId.toString());
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(finalUnWrapAmount) || Number(finalUnWrapAmount) <= 0)
    ) {
      return;
    }
    handleUnWrapAction();
  };

  return (
    <>
      <UnWrapDetailsSelectorCard
        title="You’ll Deposit"
        side="source"
        balanceUpdated={status === "success"}
      />

      <UnWrapDetailsSelectorCard
        title="You’ll Receive"
        side="destination"
        balanceUpdated={status === "success"}
      />

      {walletAddress && (
        <div className="flex items-center text-white">
          <span className="ml-2 py-1 text-[10px] flex">
            Fee on UnWrap{" "}
            {isFeeLoading ? (
              <img src={Gif.Loader} className="ml-1 h-4 w-4" />
            ) : (
              `${feeOnUnWrap}%`
            )}
          </span>
        </div>
      )}

      <Button
        variant={`${getButtonVariant()}`}
        title={`${getButtonTitle()}`}
        className="mt-4 w-full text-xl"
        prefix={getButtonPrefix()}
        postfix={getButtonPostfix()}
        disabled={isButtonDisabled()}
        onClick={handleButtonClick}
      />

      <ConnectWalletDialog
        show={showConnectWalletDialog}
        onHide={() => setShowConnectWalletDialog(false)}
      />
    </>
  );
};

export default UnWrapCard;
