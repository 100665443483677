import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Lottie from "lottie-react";
import { Animations } from "../assets";
import { ErrorMessage } from "../components/CustomMessages";
import { SuccessMessage as MintSuccessMessage } from "../page/crucible/mint/SuccessMessage";
import { SuccessMessage as StakeSuccessMessage } from "../page/crucible/stake/SuccessMessage";
import { SuccessMessage as UnStakeSuccessMessage } from "../page/crucible/un-stake/SuccessMessage";
import { SuccessMessage as UnWrapSuccessMessage } from "../page/crucible/un-wrap/SuccessMessage";
import { SuccessMessage as WithdrawalSuccessMessage } from "../page/crucible/withdraw-rewards/SuccessMessage";
import { SuccessMessage } from "../components/SuccessMessage";

const index = () => {
  return (
    <>
      <Lottie
        animationData={Animations.BackgroundGradient}
        className="absolute top-0 z-[-1]"
      />
      <Navbar />
      <div
        className={`mb-24 flex h-screen flex-col items-center pt-28 sm:mb-0`}
      >
        {/* <ErrorMessage /> */}
        <SuccessMessage/>
        {/* <MintSuccessMessage />
        <StakeSuccessMessage />
        <UnStakeSuccessMessage />
        <UnWrapSuccessMessage />
        <WithdrawalSuccessMessage /> */}
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default index;
