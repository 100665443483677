import { useRecoilState } from "recoil";
import { ReactIcons } from "../../assets";
import { Card } from "../../components/ui";
import { MessageState, messagingState } from "../../recoil/app";
import { useEffect } from "react";

interface BuyCardIprops {}
const BuyCard = ({}: BuyCardIprops) => {
  const [messageState, setMessageState] =
    useRecoilState<MessageState>(messagingState);

  useEffect(() => {
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
  }, []);

  return (
    <>
      <Card variant="secondary" className="my-4">
        <div className="relative">
          <div className="px-4 py-5">
            <div className="row-start-1 pt-2 text-md flex items-center">
              <img
                className="mr-2"
                src={
                  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png"
                }
                alt="swap Icon"
                width={25}
              />
              Arbitrum
            </div>
          </div>
          <div className="flex justify-between items-center px-20 py-1 md:pb-1">
            <div className="flex items-center cursor-pointer text-primary text-sm" onClick={()=>{
              window.open("https://swap.multiswap.network/swap?fromChainId=42161&fromToken=0xff970a61a04b1ca14834a43f5de4533ebddb5cc8&sourceSlippage=2&toChainId=42161&toToken=0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda&destinationSlippage=2","_blank");
            }}>
              <img
              className="cursor-pointer"
                src={
                  "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454"
                }
                alt="swap Icon"
                width={25}
              />
              FRM <ReactIcons.FaExternalLinkAlt className="ml-1 cursor-pointer" size={10} />
            </div>
            <div className="flex items-center cursor-pointer text-primary text-sm " onClick={()=>{
              
              window.open("https://www.sushi.com/swap?inputCurrency=0x9f6AbbF0Ba6B5bfa27f4deb6597CC6Ec20573FDA&outputCurrency=0xe685d3CC0be48BD59082eDe30C3b64CbFc0326e2&chainId=42161&token0=0x9f6AbbF0Ba6B5bfa27f4deb6597CC6Ec20573FDA&token1=0xe685d3CC0be48BD59082eDe30C3b64CbFc0326e2","_blank");
            }}>
              <img
                src={
                  "https://ferrum.network/nwp/assets/Ferrum-Network/icon-network-cfrm.svg"
                }
                alt="swap Icon"
                className="cursor-pointer"
                width={25}
              />
              cFRM <ReactIcons.FaExternalLinkAlt className="ml-1 cursor-pointer" size={10} />
            </div>
          </div>
          <div className="flex justify-between px-4 py-5 md:pb-1">
            <div className="text-se text-sm">Buy Fee: 0.3%</div>
            <div className=" text-secondary text-sm">Sell Fee: 0.3%</div>
            <div className=" text-secondary text-sm">Transfer Fee: 2%</div>
          </div>
        </div>
      </Card>

      <Card variant="secondary" className="my-4">
        <div className="relative">
          <div className="px-4 py-5">
            <div className="row-start-1 pt-2 text-md mb-2 flex items-center">
              <img
                className="mr-2"
                src={
                  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png"
                }
                alt="swap Icon"
                width={25}
              />
              BSC
            </div>
          </div>
          <div className="items-center px-20 py-1 md:pb-1 ">
            <div className="flex items-center cursor-pointer text-primary text-sm" onClick={()=>{
              window.open("https://swap.multiswap.network/swap?fromChainId=56&fromToken=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&sourceSlippage=2&toChainId=56&toToken=0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc&destinationSlippage=2","_blank");
            }}>
              <img
                src={
                  "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454"
                }
                alt="swap Icon"
                className="cursor-pointer"
                width={25}
              />
              FRM <ReactIcons.FaExternalLinkAlt className="ml-1 cursor-pointer" size={10} />
            </div>
          </div>
          <div className="flex justify-between px-4 py-5 md:pb-1">
            <div className=" text-secondary text-sm">Buy Fee: 0.3%</div>
            <div className=" cursor-pointer text-secondary text-sm">
              Sell Fee: 0.3%
            </div>
            <div className=" text-secondary text-sm">Transfer Fee: 0.3%</div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default BuyCard;
