import React, { useEffect, useState } from "react";
import { Card } from "./ui";
import { Icons, ReactIcons } from "../assets";
import { UnWrapState, unWrapingState } from "../recoil/unWrap";
import { useRecoilState } from "recoil";
import { MessageState, messagingState } from "../recoil/app";

interface MessageProps {
  message: string;
}

interface ClosableMessageProps extends MessageProps {
  onClose: () => void;
}

export const SuccessMessage: React.FC = () => {
  const [messageState,setMessageState] = useRecoilState<MessageState>(messagingState);
  const {errorMessage, successMessage} = messageState;

  // useEffect(() => {
  //   if (errorMessage !== "") {
  //     setTimeout(
  //       () =>
  //         setUnWrapState({
  //           ...unWrapState,
  //           errorMessage: "",
  //         }),
  //       1000
  //     );
  //   }
  // }, [errorMessage]);

  if (!successMessage && !errorMessage) return null;

  return (
    <Card
      variant="info"
      className={`mb-5 flex !w-auto items-center justify-between border ${errorMessage ? `border-[#c71c4a40]` : successMessage ? `border-[#1cc74740]` : ""} !p-3`}
    >
      <ReactIcons.IoIosCheckmarkCircle className={errorMessage ? `text-red-600` : successMessage ? `text-green-600` : ""} size={25} />
      <p className="mx-2 w-11/12 text-[#e8d5d5]">{errorMessage ? errorMessage : successMessage ? successMessage: ""}</p>

      <ReactIcons.IoMdClose
        className="cursor-pointer"
        size={20}
        color="white"
        onClick={() =>
          setMessageState({
            errorMessage: "",
            successMessage: "",
          })
        }
      />
    </Card>
  );
};
