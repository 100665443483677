import React, { useEffect, useState } from "react";
import { Card, Button } from "../../components/ui";
import { Images } from "../../assets";

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ConnectWalletDialog from "../../components/ConnectWalletDialog";
import BuyCard from "./BuyCard";
import MintCard from "./mint/MintCard";
import Stake from "./stake/Stake";
import UnStake from "./un-stake/UnStake";
import UnWrap from "./un-wrap/UnWrap";
import WithdrawRewards from "./withdraw-rewards/WithdrawRewards";
import SideMenu from "../../layouts/SideMenu";

const CrucibleCard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showWalletConenctDialog, setShowWalletConenctDialog] = useState(false);

  useEffect(() => {
    if (pathname) {
      if (pathname === "/") {
        navigate("/mint");
      }
    }
  }, [pathname]);

  return (
    <>
      <Card variant="primary" className="shadow-2xl !p-3">
        <header>
          <div className="block md:hidden">
            <SideMenu />
          </div>
          <div className="hidden md:grid md:grid-cols-6 md:gap-2 md:h-16 md:items-center md:justify-between md:px-2 md:space-x-2">
            <Button
              variant={`${pathname === "/buy" ? "primary" : "secondary"}`}
              title="Buy"
              className="bg-background px-auto py-[10px] font-normal !text-sm"
              onClick={() => {
                navigate("/buy");
              }}
            />
            <Button
              variant={`${pathname === "/mint" ? "primary" : "secondary"}`}
              title="Mint"
              className="bg-background px-auto py-[10px] font-normal !text-sm"
              onClick={() => {
                navigate("/mint");
              }}
            />
            <Button
              variant={`${pathname === "/stake" ? "primary" : "secondary"}`}
              title="Stake"
              className="bg-background px-auto py-[10px] font-normal !text-sm"
              onClick={() => {
                navigate("/stake");
              }}
            />
            <Button
              variant={`${pathname === "/withdraw-rewards" ? "primary" : "secondary"}`}
              title="Rewards"
              className="bg-background py-[10px] font-normal !text-sm flex justify-center"
              onClick={() => {
                navigate("/withdraw-rewards");
              }}
            />
            <Button
              variant={`${pathname === "/un-stake" ? "primary" : "secondary"}`}
              title="UnStake"
              className="bg-background !p-0 !px-auto !py-[10px] font-normal !text-sm"
              onClick={() => {
                navigate("/un-stake");
              }}
            />
            <Button
              variant={`${pathname === "/un-wrap" ? "primary" : "secondary"}`}
              title="UnWrap"
              className="bg-background !p-0 !sm:text-sm !px-auto !py-[10px] font-normal !text-sm"
              onClick={() => {
                navigate("/un-wrap");
              }}
            />
          </div>
        </header>

        <article className="relative">
          <Routes>
            <Route index element={<BuyCard />} />
            <Route path="/buy" element={<BuyCard />} />
            <Route path="/mint" element={<MintCard />} />
            <Route path="/stake" element={<Stake />} />
            <Route path="/un-stake" element={<UnStake />} />
            <Route path="/un-wrap" element={<UnWrap />} />
            <Route path="/withdraw-rewards" element={<WithdrawRewards />} />
            <Route path="*" element={<Navigate to={"/mint"}/>} />
          </Routes>
        </article>
      </Card>
      <img src={Images.FerrumBanner} alt="ferrum banner" className="" />

      <ConnectWalletDialog
        show={showWalletConenctDialog}
        onHide={() => setShowWalletConenctDialog(false)}
      />
    </>
  );
};

export default CrucibleCard;
