import React, { useEffect, useState } from "react";
import { Icons } from "../assets";
import { Button } from "../components/ui";
import SideMenu from "./SideMenu";
import ConnectWalletDialog from "../components/ConnectWalletDialog";
import {
  useConnectivity,
  useFoundrySendTransaction,
  useWalletConnector,
} from "foundry";
import { truncateMiddle } from "../utils/helper";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { selectedWalletState } from "../recoil/wallet";
import {
  allNetworksListState,
  connectedNetworkState,
  swapAndWithdrawTxStatusState,
} from "../recoil/swap";
import * as authServer from "../api/AuthCrud";
import * as networkServer from "../api/NetworkCrud";
import { AxiosError } from "axios";
import { useTokenBalance } from "../hooks";
import {
  C_FRM_CONTRACT_ADDRESS,
  FRM_CONTRACT_ADDRESS,
} from "../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import useRefreshAuth from "../hooks/useRefreshAuth";
import TokenBalance from "./TokenBalance";
import { MessageState, messagingState } from "../recoil/app";

const Navbar = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const selectedWallet = useRecoilValue(selectedWalletState);
  const swapAndWithdrawTxStatus = useRecoilState(swapAndWithdrawTxStatusState);
  const [connectedNetwork, setConnectedNetwork] = useRecoilState(
    connectedNetworkState
  );
  const { isConnected, walletAddress, currentWallet, currentNetworkChainId } =
    useWalletConnector();
  const { walletConnectionHandler } = useConnectivity();
  const [allNetworksList, setAllNetworksList] =
    useRecoilState(allNetworksListState);
  const [messageState, setMessageState] =
    useRecoilState<MessageState>(messagingState);

  const { setToken } = useRefreshAuth();

  useEffect(() => {
    fetchNetworks();
  }, []);

  useEffect(() => {
    if (isConnected && showDialog) {
      setShowDialog(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected) {
      authenticateWallet(walletAddress, currentNetworkChainId);
    } else {
      setToken("");
    }
    if (allNetworksList.length && currentNetworkChainId) {
      getConnectedNetworkByChainId(
        allNetworksList,
        currentNetworkChainId.toString()
      );
    }
  }, [isConnected, allNetworksList, currentNetworkChainId]);

  const fetchNetworks = async () => {
    try {
      const response = await networkServer.getAllNetworks();
      setAllNetworksList(response.data.body.networks);
    } catch (error) {
      console.error("Error fetching networks:", error);
    }
  };

  const getConnectedNetworkByChainId = (
    allNetworksList: any[],
    chainId: number | string
  ) => {
    allNetworksList.forEach((item: any) => {
      if (item?.chainId === chainId) {
        setConnectedNetwork(item);
      }
    });
  };

  const authenticateWallet = async (
    walletAddress: string,
    currentNetworkChainId: number
  ) => {
    try {
      const response = await authServer.getAccessTokenForApplicationUser();
      if (response?.data.body?.token) {
        let data = {
          address: walletAddress,
          ferrumNetworkIdentifier: currentNetworkChainId,
          role: "communityMember",
        };
        const res = await authServer.connectToWalletAddress(
          data,
          response.data.body.token
        );
        setToken(res.data.body.token);
      }
    } catch (error) {
      console.error("Error authenticating wallet:", error);
      if (error instanceof AxiosError) {
      } else if (error instanceof Error) {
      }
    }
  };

  return (
    <>
      <nav className="fixed inset-x-0 top-0 z-10 block h-20 w-full bg-backgroundDark shadow-lg sm:shadow-none lg:bg-transparent">
        <div className="flex items-center justify-between p-5">
          <Link to={"/"} className="flex items-center space-x-3">
            <img src={Icons.MsLogo} className="h-8" alt="MultiSwap Logo" />
          </Link>
          <div className="absolute inset-0 -z-10 hidden items-center justify-center lg:flex">
            <Button
              prefix={<img src={Icons.SwapIcon} alt="MultiSwap Icon" />}
              variant="secondary"
              title="Crucible"
              rounded="full"
              className="bg-background px-4 py-[10px] font-normal"
            />
          </div>
          {!isConnected ? (
            <div onClick={() => setShowDialog(true)}>
              <Button
                variant="primary"
                title="Connect Wallet"
                rounded="full"
                className="px-4 py-[10px] text-xs font-normal sm:text-sm"
              />
            </div>
          ) : (
            <div className="flex items-center">
              <TokenBalance
                walletAddress={walletAddress}
                tokenAddress={
                  C_FRM_CONTRACT_ADDRESS[`${currentNetworkChainId}`]
                }
                balanceUpdated={
                  messageState.errorMessage !== "" ||
                  messageState.successMessage !== ""
                }
                rpcUrl={connectedNetwork?.rpcUrl}
                tokenName="cFRM"
                tokenImage="https://ferrum.network/nwp/assets/Ferrum-Network/icon-network-cfrm.svg"
              />

              <TokenBalance
                walletAddress={walletAddress}
                tokenAddress={FRM_CONTRACT_ADDRESS[`${currentNetworkChainId}`]}
                balanceUpdated={
                  messageState.errorMessage !== "" ||
                  messageState.successMessage !== ""
                }
                rpcUrl={connectedNetwork?.rpcUrl}
                tokenName="FRM"
                tokenImage="https://etherscan.io/token/images/ferrumnetwork_32.png?=v454"
              />

              <div
                className="mr-2 flex w-44 cursor-pointer justify-evenly rounded-full bg-background p-2"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <img
                  src={selectedWallet?.logo}
                  alt="ms Icon"
                  className="h-5 w-5"
                />
                <img
                  src={connectedNetwork?.logo}
                  alt="network logo"
                  className="h-5 w-5"
                />
                <span className="text-sm text-white">
                  {truncateMiddle(walletAddress, 12)}
                </span>
                {dropdownOpen && (
                  <div className=" right-18 absolute top-16 rounded-md bg-background shadow-lg">
                    <button
                      onClick={() => walletConnectionHandler(currentWallet, "")}
                      className="mx-4 block rounded-md bg-background px-4 py-2 text-center text-xs text-secondary"
                    >
                      Disconnect
                    </button>
                  </div>
                )}
              </div>
              {/* <SideMenu /> */}
            </div>
          )}
        </div>
      </nav>
      <ConnectWalletDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />
    </>
  );
};

export default Navbar;
