import { useEffect } from "react";
import { useTokenBalance } from "../hooks";

interface FrmBalanceIprops {
  walletAddress: string;
  tokenAddress: string;
  rpcUrl: string;
  tokenName: string;
  tokenImage: string;
  balanceUpdated: boolean;
}

const TokenBalance = ({
  walletAddress,
  tokenAddress,
  rpcUrl,
  tokenName,
  tokenImage,
  balanceUpdated,
}: FrmBalanceIprops) => {
  const { tokenBalance, fetchTokenBalance } = useTokenBalance(
    walletAddress,
    balanceUpdated,
    tokenAddress,
    false,
    rpcUrl
  );
  useEffect(()=>{
    if(balanceUpdated){
      fetchTokenBalance(walletAddress,tokenAddress,false,rpcUrl);
    }
  },[balanceUpdated])

  return (
    <div className="mr-5 hidden items-center justify-between sm:flex">
      <img src={tokenImage} alt="swap Icon" width={25} />
      <span className="ml-1 text-sm text-white">
        {tokenBalance ? Number(tokenBalance).toFixed(4) : "0.00"}
      </span>
      <span className="ml-1 text-sm text-primary">{tokenName}</span>
    </div>
  );
};

export default TokenBalance;
