import React, { useEffect, useState } from "react";
import { Icons, ReactIcons } from "../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../components/ui";

const SideMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<{
    title: string;
    path: string;
  } | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/buy");
    } else {
      setSelectedButton({
        title: getButtonTitle(pathname),
        path: pathname,
      });
    }
  }, [pathname, navigate]);

  const getButtonTitle = (path: string) => {
    switch (path) {
      case "/buy":
        return "Buy";
      case "/mint":
        return "Mint";
      case "/stake":
        return "Stake";
      case "/un-stake":
        return "UnStake";
      case "/un-wrap":
        return "UnWrap";
      case "/withdraw-rewards":
        return "Withdraw";
      default:
        return "";
    }
  };

  const handleNavigation = (path: string, title: string) => {
    navigate(path);
    setSelectedButton({ title, path });
    setIsMenuOpen(false);
  };

  return (
    <div className="relative z-10 flex justify-end ">
      <div
        className="flex justify-end pt-3 pr-3 h-10 w-10 rounded-lg  text-sm text-white !p-0 !mt-3"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen && selectedButton ? (
          <div className="flex">
            <Button
              variant="primary"
              title={selectedButton.title}
              className="bg-background font-normal !text-sm w-[112px]"
              onClick={() => setIsMenuOpen(false)}
            />
            <ReactIcons.RiArrowDropDownLine
              size={30}
              className="cursor-pointer mt-2"
            />
          </div>
        ) : (
          <div className="flex">
            <Button
              variant="primary"
              title={selectedButton?.title}
              className="bg-background font-normal !text-sm w-[112px]"
              onClick={() => setIsMenuOpen(false)}
            />
            <ReactIcons.RiArrowDropDownLine
              size={30}
              className="cursor-pointer mt-2"
            />
          </div>
        )}
      </div>
      <div
        className={`${!isMenuOpen ? "hidden" : ""} absolute top-16 w-36 rounded-sm bg-background shadow`}
      >
        <div className=" text-xs text-white grid gap-1">
          <Button
            variant={`${pathname === "/buy" ? "primary" : "quaternary"}`}
            title="Buy"
            className="bg-background px-auto font-normal !text-sm"
            onClick={() => handleNavigation("/buy", "Buy")}
          />
          <Button
            variant={`${pathname === "/mint" ? "primary" : "quaternary"}`}
            title="Mint"
            className="bg-background px-auto py-[10px] font-normal !text-sm"
            onClick={() => handleNavigation("/mint", "Mint")}
          />
          <Button
            variant={`${pathname === "/stake" ? "primary" : "quaternary"}`}
            title="Stake"
            className="bg-background px-auto py-[10px] font-normal !text-sm"
            onClick={() => handleNavigation("/stake", "Stake")}
          />
          <Button
            variant={`${pathname === "/un-stake" ? "primary" : "quaternary"}`}
            title="UnStake"
            className="bg-background !p-0 !px-auto !py-[10px] font-normal !text-sm"
            onClick={() => handleNavigation("/un-stake", "UnStake")}
          />
          <Button
            variant={`${pathname === "/un-wrap" ? "primary" : "quaternary"}`}
            title="UnWrap"
            className="bg-background !p-0 !sm:text-sm !px-auto !py-[10px] font-normal !text-sm"
            onClick={() => handleNavigation("/un-wrap", "UnWrap")}
          />
          <Button
            variant={`${pathname === "/withdraw-rewards" ? "primary" : "quaternary"}`}
            title="Withdraw"
            className="bg-background py-[10px] font-normal !text-sm flex justify-center"
            onClick={() => handleNavigation("/withdraw-rewards", "Withdraw")}
          />
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
